<template>
    <div class="ma-5"
         :class="{'text-center': alignment === 'center',
                    'text-left': alignment === 'left',
                    'text-right': alignment === 'right',
                    'text-center': alignment === undefined}">
        <v-progress-circular
                indeterminate
                :color="$variables.v.theme.secondary"
        ></v-progress-circular>
    </div>
</template>

<script>
    export default {
        props: ['alignment'],
    }
</script>